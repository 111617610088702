import { Route, Switch, useRouteMatch } from 'react-router-dom'
import CerecProthetik from './CerecProthetik';
import Chirurgie from './Chirurgie';
import Desensibilisierung from './Desensibilisierung';
import Endodontie from './Endodontie';
import Implantologie from './Implantologie';
import Paradontologie from './Paradontologie';
import Schmerztherapie from './Schmerztherapie';
import SirolaserHome from './SirolaserHome';

interface Props {
	
}

const Sirolaser = (props: Props) => {

	let { url } = useRouteMatch();

	return (
		<Switch>
			<Route path={`${url}/implantologie`}>
				<Implantologie />
			</Route>
			<Route path={`${url}/endodontie`}>
				<Endodontie />
			</Route>
			<Route path={`${url}/paradontologie`}>
				<Paradontologie />
			</Route>
			<Route path={`${url}/chirurgie`}>
				<Chirurgie />
			</Route>
			<Route path={`${url}/cerec-prothetik`}>
				<CerecProthetik />
			</Route>
			<Route path={`${url}/schmerztherapie`}>
				<Schmerztherapie />
			</Route>
			<Route path={`${url}/desensibilisierung`}>
				<Desensibilisierung />
			</Route>
			<Route path={`${url}`}>
				<SirolaserHome />
			</Route>
		</Switch>
	)
}

export default Sirolaser
