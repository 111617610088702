import Section from '../../components/section'

import graphic1 from './img/Implantologie_01.png'
import graphic2 from './img/Implantologie_02.png'

interface Props {
	
}

const Implantologie = (props: Props) => {

	return (
		<div>
			<Section>
				<h1>
					SiroLaser Blue - Implantologie
				</h1>
				<ul>
					<li>
						Implantfreilegung
					</li>
					<li>
						Periimplantitis Therapie (im sinne der Mucositis)
					</li>
					<li>
						Periimplantäres Weichgewebsmanagement
					</li>
					<li>
						Implantatdekontamination
					</li>
					<li>
						Modellierung des Kronenbettes
					</li>
					<li>
						Sofortige Abformung möglich
					</li>
				</ul>

				<div style={{display: 'flex', justifyContent: 'flex-end', gap: 100, width: '100%'}}>
					<img src={graphic1} alt="Graphic1" style={{width: 166}} className="with-border"/>
					<img src={graphic2} alt="Graphic2" style={{width: 166}}  className="with-border"/>
				</div>
			</Section>
		</div>
	)
}

export default Implantologie
