import { useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import { CalloutLink } from '../../components/callout'
import Section from '../../components/section'

interface Props {
	
}

const CalloutGrid = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	gap: 130px;
	margin-bottom: 40px;

	> * {
		width: 230px;
	}
`

const SirolaserHome = (props: Props) => {

	let { url } = useRouteMatch();

	return (
		<div>
			<Section>
				<h1>
					SiroLaser Blue – vielfältige Einsatzgebiete
				</h1>

				<CalloutGrid>
					<CalloutLink to={`${url}/implantologie`} withMorelink>
						<p>
							Implantologie
						</p>
					</CalloutLink>
					<CalloutLink to={`${url}/endodontie`} withMorelink>
						<p>
							Endodontie
						</p>
					</CalloutLink>
					<CalloutLink to={`${url}/paradontologie`} withMorelink>
						<p>
							Parodontologie
						</p>
					</CalloutLink>
				</CalloutGrid>

				<CalloutGrid>
					<CalloutLink to={`${url}/chirurgie`} withMorelink>
						<p>
							Chirurgie
						</p>
					</CalloutLink>
					<CalloutLink to={`${url}/cerec-prothetik`} withMorelink>
						<p>
							CEREC / Prothetik
						</p>
					</CalloutLink>
				</CalloutGrid>
				<CalloutGrid>
					<CalloutLink to={`${url}/desensibilisierung`} withMorelink>
						<p>
							Desensibilisierung
						</p>
					</CalloutLink>
					<CalloutLink to={`${url}/schmerztherapie`} withMorelink>
						<p>
							Schmerztherapie
						</p>
					</CalloutLink>
					<div></div>
				</CalloutGrid>

			</Section>
		</div>
	)
}

export default SirolaserHome
