import Grid from '../../components/grid'
import Section from '../../components/section'
import Spacer, { SmallSpacer } from '../../components/spacer'

import take1Graphic from './img/aquasil-ultra-take-1.png';
import flexitimeGraphic from './img/aquasil-ultra-flexitime.png';
import impregumGraphic from './img/aquasil-ultra-impregum.png';
import aquasilultraGraphic from './img/aquasil-ultra-aquasil-ultra.png';
import tableGraphic from './img/aquasil-table.svg';
import geschmackGraphic from './img/aquasil-geschmack.svg';
import Footnotes from '../../components/footnotes';

interface Props {
	
}

const AquasilUltra = (props: Props) => {
	return (
		<div>
			<Section>
				<h1>74% der Zahnärzte wünschen sich ein Material, das sich für alle klinischen Indikationen eignet. Nicht nur für die einfachen Fälle.¹</h1>
				<h1>
					Vorteilhafte Eigenschaften & Nutzen
				</h1>
				<ul>
					<li>
						Hohe intraorale Reißfestigkeit
					</li>
					<li>
						Hohe Reißfestigkeit auch nach 24 h
					</li>
					<li>
						Hydrophilie
					</li>
					<li>
						Variable Verarbeitungs- und Abbindezeiten
					</li>
				</ul>
			</Section>

			<h1>Aquasil® Ultra+</h1>
			<p>
				Aquasil Ultra+ erlaubt äußerst detailgenaue Abformungen bei geringerer Gefahr von Hohlräumen und Abrissen im Randbereich, den laut Dentallaboren häufigsten Abformfehlern.¹ Dank exakter und stabiler Detailwiedergabe in Verbindung mit zielgenauer Applikation und optimalen Verarbeitungs- und Abbindezeiten ist Aquasil Ultra+ die kompromisslose Lösung für Abformungen in Ihrem Praxisalltag.
			</p>
			<ul>
				<li>
					Verbesserte Hydrophilie für höhere Präzision als je zuvor
				</li>
				<li>
					Verbesserte Reißfestigkeit für mehr Sicherheit
				</li>
				<li>
					Verschiedene Optionen bei Abbindezeiten für bessere klinische Resultate
				</li>
			</ul>

			<SmallSpacer />

			<Grid gridTemplateColumns="repeat(4,1fr)">
				<img src={take1Graphic} alt="Take1" className="with-border"/>
				<img src={flexitimeGraphic} alt="Flexitime" className="with-border"/>
				<img src={impregumGraphic} alt="Impregum" className="with-border"/>
				<img src={aquasilultraGraphic} alt="Aquasil Ultra" className="with-border"/>
			</Grid>
			<p className="small-text">
				Wassertropfentest auf nicht abgebundenem Material, Kontaktwinkel 2 Sekunden nach Benetzung bei 80% relativer Feuchtigkeit
			</p>

			<Spacer />
			<Grid gridTemplateColumns="1fr 1fr">
				<img src={tableGraphic} alt="Table" style={{width: 420}} />
				<img src={geschmackGraphic} alt="Table" style={{width: 205, marginLeft: 'auto'}} />
			</Grid>
			<Spacer />	
			<Footnotes>
				¹ Daten auf Anfrage
			</Footnotes>
		</div>
	)
}

export default AquasilUltra
