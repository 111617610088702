import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import graphic from './HomeGraphic.png';

const GraphicWrapper = styled.div`
  display: block;
  position: relative;
  width: 1423px;

  @media (min-width: 1500px) {
    width: 100%;
  }
`

interface LinkOverlayProps {
	x?: string|number
	y?: string|number
}

const LinkOverlay = styled.div<LinkOverlayProps>`
  display: block;
  position: absolute;
  width: 110px;
  height: 157px;
  top: ${props => props.y ? props.y : 0}%;
  left: ${props => props.x ? props.x : 0}%;

  &:active {
    background-color: rgba(0, 0, 0, 0.4);
  }

`

function Icon() {
  return (
    <GraphicWrapper >
      <img src={graphic} alt="home graphic" style={{width: 1423}} width="1423" height="575"/>
      {/* Endo */}
      <Link to="/endo/smartlitepro">
        <LinkOverlay y="26" x="19.6"></LinkOverlay>
      </Link>
      <Link to="/endo/spuelungaktivierung">
        <LinkOverlay y="26" x="27.2"></LinkOverlay>
      </Link>
      <Link to="/endo/temporaereeinlage">
        <LinkOverlay y="26" x="34.8"></LinkOverlay>
      </Link>
      <Link to="/endo/obturation">
        <LinkOverlay y="26" x="42.5"></LinkOverlay>
      </Link>

      {/* Lichthärtung */}
      <Link to="/lichthaertung">
        <LinkOverlay y="5.5" x="63.5" style={{height: 120}}></LinkOverlay>
      </Link>

      {/* Lichthärtung */}
      <Link to="/direkte">
        <LinkOverlay y="0" x="57" style={{height: 35, width: 420}}></LinkOverlay>
      </Link>

      {/* Indirekte */}
      <Link to="/indirekte/sirolaser">
        <LinkOverlay y="50" x="56"></LinkOverlay>
      </Link>
      <Link to="/indirekte/aquasilultra">
        <LinkOverlay y="50" x="63.8"></LinkOverlay>
      </Link>
      <Link to="/indirekte/integritymulticure">
        <LinkOverlay y="50" x="75"></LinkOverlay>
      </Link>
      <Link to="/indirekte/calibracements">
        <LinkOverlay y="50" x="86.5"></LinkOverlay>
      </Link>

      {/* Sirolaser */}
      <Link to="/sirolaser">
        <LinkOverlay y="50" x="0">
          <p style={{color: "var(--color__primary)", padding: 20, fontSize: 16}}>
            Siro Laser Demolink
          </p>
        </LinkOverlay>
      </Link>
    </GraphicWrapper>
  );
}

export default Icon;
