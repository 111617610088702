import styled from 'styled-components'

interface Props {
	gridTemplateColumns: string	
}

const Grid = styled.div<Props>`
	display: grid;
	gap: 20px;
	grid-template-columns: ${props => props.gridTemplateColumns ? props.gridTemplateColumns : 'auto'};
	width: 100%;

	img {
		max-width: 100%;
	}
	
	@media (max-width: 900px) {
		grid-template-columns: 1fr;
	}
`


export default Grid