import React from 'react'
import { Link, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import logo from './dentsply-logo.svg'

interface Props {
	
}

const Bar = styled.div`
	background-image: linear-gradient( to left, var(--color__primary), var(--color__secondary));
	width: 100%;
	height: 8px;
`

const HeaderWrapper = styled.div`
	margin-bottom: 50px;
	display: grid;
	grid-template-columns: 1fr 152px;
	grid-column-gap: 20px;
	grid-row-gap: 20px;
	align-items: flex-start;

	.bar {
		grid-column-start: 1;
		grid-column-end: 3
	}

	h1,
	h2 {
		margin-top: 0;
		margin-bottom: 0;
	}

	h2 {
		font-size: 16px;
	}

	a {
		color: inherit;
		text-decoration: none;
	}

	@media (max-width: 800px) {
		grid-template-columns: 1fr 65px;

		h1,
		h2 {
			line-height: 1em;
			font-size: 25px;
		}
	}
`

export const Header = (props: Props) => {
	return (
		<HeaderWrapper>
			<Bar className="bar"/>
			<div className="content">
				<Switch>
					<Route path="/" exact>
						<h1>Root to Crown</h1>
					</Route>
					<Route path="/">
						<Link to="/">
							<h2>Root to Crown</h2>
						</Link>
					</Route>
				</Switch>
			</div>
			<div className="logo">
				<Link to="/">
					<img src={logo} alt="logo" width="152" height="43"/>
				</Link>
			</div>
		</HeaderWrapper>
	)
}