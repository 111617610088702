import Grid from '../../components/grid'
import Section from '../../components/section'
import Spacer, { SmallSpacer } from '../../components/spacer'

import multicureGraphic from './img/multicure-graphic.svg';
import integrityTempGripGraphic from './img/integrity-temp-grip.svg';
import integrityTempGripBiegfestigkeitGraphic from './img/integrity-temp-grip-biegfestigkeit.svg';
import integrityTempGripFilmstaerkeGraphic from './img/integrity-temp-grip-filmstaerke.svg';
import provisorischesMaterialGraphic from './img/integrity-multicure-provisorisches-material.png';

import Callout from '../../components/callout';
import Footnotes from '../../components/footnotes';

interface Props {
	
}

const IntegrityMultiCure = (props: Props) => {
	return (
		<div>
			<Section>
				<h1>
					Reparaturen oder Erneuerungen von Provisorien können Ihre Praxiseinnahmen um mehrere hundert Euro pro Monat verringern.¹
				</h1>
				<h1>
					Jeder finale Zementierungstermin wird durch die Wahl des temporären Zements beeinflusst.²
				</h1>
				<h1>
					Vorteilhafte Eigenschaften & Nutzen
				</h1>
				<ul>
					<li>
						Langlebigkeit - ungeplante Termine für Reparaturen oder Erneuerungen des Provisoriums zu vermeiden.
					</li>
					<li>
						Patientenfreundlich - die volle Kaufunktion in Verbindung mit hoher Ästhetik wiederherzustellen.
					</li>
					<li>
						Effizient – der Zement löst sich problemlos ab, ohne zu zerbröckeln, und spart so Zeit bei der Versäuberung. 
					</li>
					<li>
						Eugenolfreie Formulierung – keinen Einfluss auf die spätere definitive Zementierung.
					</li>
				</ul>
			</Section>

			<h1>Provisorische Versorgung</h1>
			<Grid gridTemplateColumns="1fr 1fr">
				<p>
					Langlebigkeit und Effizienz
				</p>
				<Callout calloutStyle="info" style={{width: 180, marginLeft: 'auto', textAlign: 'center'}}>
					Eugenolfrei!
				</Callout>
			</Grid>

			<h1>
				Integrity® Multi•Cure
			</h1>

			<img src={multicureGraphic} alt="multicure" style={{width: '100%'}}/>

			<SmallSpacer />

			<h1>
				Integrity® TempGrip®
			</h1>
			<div style={{textAlign: "center"}}>
				<img src={integrityTempGripGraphic} alt="Temp Grip" style={{width: '100%', maxWidth: 657}}/>
			</div>

			<Spacer />
			
			<div style={{textAlign: "center"}}>
				<img src={integrityTempGripBiegfestigkeitGraphic} alt="Temp Grip Biegfestigkeit" style={{width: '100%', maxWidth: 714}}/>
			</div>

			<Spacer />
			
			<div style={{textAlign: "center"}}>
				<img src={integrityTempGripFilmstaerkeGraphic} alt="Temp Grip Biegfestigkeit" style={{width: '100%', maxWidth: 714}}/>
			</div>

			<Spacer />

			<h1>
				Exzellente Haftung am provisorischen Material
			</h1>
			<SmallSpacer />
			<div style={{textAlign: "center"}}>
				<img src={provisorischesMaterialGraphic} alt="provisorisches material" style={{maxWidth: 152}}/>
			</div>

			<Spacer />

			<Footnotes>
				<p>
					¹ Daten auf Anfrage
				</p>
				<p>
					² Keine eingetragenes Warenzeichen von Dentsply Sirona, Inc.
				</p>
			</Footnotes>
		</div>
	)
}

export default IntegrityMultiCure
