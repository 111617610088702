import { Route, Switch, useRouteMatch } from 'react-router-dom'
import FrageRestaurationen from './FrageRestaurationen'
import RestaurationenAlternative from './RestaurationenAlternative'
import RestaurationenKomposit from './RestaurationenKomposit'
import RestaurationenKompositHerausforderung1 from './RestaurationenKompositHerausforderung1'
import RestaurationenKompositHerausforderung2 from './RestaurationenKompositHerausforderung2'
import RestaurationenKompositHerausforderung4 from './RestaurationenKompositHerausforderung4'
import RestaurationenKompositHerausforderung5 from './RestaurationenKompositHerausforderung5'
import RestaurationenKompositHerausforderung6 from './RestaurationenKompositHerausforderung6'

interface Props {
	
}

const Direkte = (props: Props) => {

	let { url } = useRouteMatch();

	return (
		<Switch>
			<Route path={`${url}/restaurationen-komposit`}>
				<RestaurationenKomposit />
			</Route>
			<Route path={`${url}/restauration-komposit-herausforderung1`}>
				<RestaurationenKompositHerausforderung1 />
			</Route>
			<Route path={`${url}/restauration-komposit-herausforderung2`}>
				<RestaurationenKompositHerausforderung2 />
			</Route>
			<Route path={`${url}/restauration-komposit-herausforderung4`}>
				<RestaurationenKompositHerausforderung4 />
			</Route>
			<Route path={`${url}/restauration-komposit-herausforderung5`}>
				<RestaurationenKompositHerausforderung5 />
			</Route>
			<Route path={`${url}/restauration-komposit-herausforderung6`}>
				<RestaurationenKompositHerausforderung6 />
			</Route>
			<Route path={`${url}/restaurationen-alternative`}>
				<RestaurationenAlternative />
			</Route>
			<Route path={`${url}`}>
				<FrageRestaurationen />
			</Route>
		</Switch>
	)
}

export default Direkte
