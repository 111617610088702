import React from 'react'
import { Link } from 'react-router-dom'
import Button, { ButtonGroup } from '../../components/button'
import Footnotes from '../../components/footnotes'
import Section from '../../components/section'
import { SmallSpacer } from '../../components/spacer'

interface Props {
	
}

const FrageRestaurationen = (props: Props) => {
	return (
		<div>
			<Section>
				<h1>50% aller Restaurationen drehen sich um die Klasse II.¹</h1>
				<p>
					Mehr als 25 % der klinischen Situationen sind nicht ideal, sodass eine Alternative zu Komposit gewünscht wird.²
				</p>
				<SmallSpacer />
				<ButtonGroup>
					<Link to="/direkte/restaurationen-komposit">
						<Button buttonStyle="secondary">
							Komposit
						</Button>
					</Link>
					<Link to="/direkte/restaurationen-alternative">
						<Button>
							Alternative
						</Button>
					</Link>
				</ButtonGroup>
			</Section>
			<Footnotes>
				<p>
					¹ American Dental Association Procedure Recap Report (2006)
				</p>
				<p>
					² Internationale Umfrage (Frankreich, Deutschland, USA) mit n = 200 Zahnärzten.
				</p>
			</Footnotes>
		</div>
	)
}

export default FrageRestaurationen
