import { Route, Switch, useRouteMatch } from 'react-router-dom'
import FrageSmartLitePro from './FrageSmartLitePro'
import SmartLitePro from './SmartLitePro'

interface Props {
	
}

const Lichthaertung = (props: Props) => {

	let { url } = useRouteMatch();

	return (
		<Switch>
			<Route path={`${url}/smartlitepro`}>
				<SmartLitePro />
			</Route>
			<Route path={`${url}`}>
				<FrageSmartLitePro />
			</Route>
		</Switch>
	)
}

export default Lichthaertung
