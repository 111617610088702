import Grid from '../../components/grid'
import sdrFlow from './img/sdr-flow.png'
import polymerisationsstressGraphic from './img/polymerisationsstress.svg'
import StyledOl from '../../components/styled-ol'
import Spacer from '../../components/spacer'

interface Props {
	
}

const RestaurationenKompositHerausforderung4 = (props: Props) => {
	return (
		<div>
			<h1>SDR® flow+</h1>

			<Grid gridTemplateColumns="4fr 8fr">

				<div className="small-text">
					<p>
						<strong>
							Herausforderung
						</strong>
					</p>
					<p>
						Sichere Randdichtigkeit und zeiteffizientes Vorgehen
					</p>
					<p>
						<strong>Lösung</strong> SDR® flow+
					</p>

					<ul className="small-text">
						<li>
							Bis zu 40% Zeitersparnis vs. Schicht-Technik
						</li>
						<li>
							Selbstnivellierung für exzellente Kavitätenadaptation
						</li>
						<li>
							Bis zu 60% weniger Polymerisationsstress
						</li>
						<li>
							Höhere Verschleißfestigkeit und Röntgenopazität
						</li>
						<li>
							Langjähriger klinischer Erfolg
						</li>
					</ul>
				</div>

				<div>
					<img src={sdrFlow} alt="SDR Flow" style={{maxWidth: 490}} />

					<Grid gridTemplateColumns="1fr 1fr">
						<div>
							<h3>Konventionelle Technik</h3>
							<p className="list-headline">
								Bonding
							</p>
							<StyledOl
								options={[
									"Fließfähiges Komposit (Liner)",
									"Inkrement 1: Universalkomposit",
									"Inkrement 2: Universalkomposit",
									"Inkrement 3: Universalkomposit"
								]}
							>
							</StyledOl>
						</div>
						<div>
							<h3>SDR® flow+ Technik</h3>

							<p className="list-headline">
								Bonding
							</p>
							<StyledOl
								options={[
									"SDR® flow+ bis zu 4 mm",
									"Deckschicht Universalkomposit (2mm)",
								]}
							>
							</StyledOl>
						</div>
					</Grid>
				</div>
			</Grid>

			<Spacer />

			<p>
				<strong>
					Polymerisationsstress
				</strong>
				<br />
				Dargestellt im Verhältnis von SDR® flow+ vs. Wettbewerb
			</p>

			<img src={polymerisationsstressGraphic} alt="Polymerisationsstress Dargestellt im Verhältnis von SDR® flow+ vs. Wettbewerb" style={{width: '100%'}}/>
			
			<Spacer />
		</div>
	)
}

export default RestaurationenKompositHerausforderung4
