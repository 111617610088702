import Grid from '../../components/grid'
import Spacer, { SmallSpacer } from '../../components/spacer'
import Footnotes from '../../components/footnotes'

import scotchbondUniversalGraphic from './img/scotchbond-universal.png'
import ibondUniversalGraphic from './img/ibond-universal.png'
import primebondActiveGraphic from './img/primebond-active.png'
import scherhaftigkeitDesDentingGraphic from './img/scherhaftigkeit-des-dentin.svg'
import wasserkontaminationGraphic from './img/wasserkontamination.svg'

interface Props {
	
}

const RestaurationenKompositHerausforderung2 = (props: Props) => {
	return (
		<div>
			<h1>Prime&Bond active</h1>

			<Grid gridTemplateColumns="1fr 1fr">

				<div className="small-text">
					<p>
						<strong>
							Herausforderung
						</strong>
					</p>
					<p>
						Zuverlässiger Haftverbund 
					</p>
					<p>
					<strong>Lösung</strong> Prime&Bond active
					</p>

					<ul className="small-text">
						<li>
							Zuverlässiger, spaltfreier Adhäsivverbund
						</li>
						<li>
							Praktisch keine postoperativen Überempfindlichkeiten
						</li>
						<li>
							Für alle Ätztechniken und Indikationen
						</li>
					</ul>
				</div>

				<div>
					<Grid gridTemplateColumns="120px 1fr">
						<img src={scotchbondUniversalGraphic} alt="" />
						<div>
							<h2>
								ScotchbondTM Universal¹
							</h2>
							<p className="small-text">
								Kann die Oberflächenspannung des Wassers nicht überwinden. Adhäsiv und Wasser mischen sich nicht. 
							</p>
						</div>
					</Grid>
					<SmallSpacer />
					<Grid gridTemplateColumns="120px 1fr">
						<img src={ibondUniversalGraphic} alt="" />
						<div>
							<h2>
								iBond® Universal¹
							</h2>
							<p className="small-text">
								Kann die Oberflächenspannung des Wassers nicht überwinden. Adhäsiv und Wasser mischen sich nicht. 
							</p>
						</div>
					</Grid>
					<SmallSpacer />
					<Grid gridTemplateColumns="120px 1fr">
						<img src={primebondActiveGraphic} alt="" />
						<div>
							<h2>
								Prime&Bond active®
							</h2>
							<p className="small-text">
								Ein einzelner Adhäsivtropfen wurde mit einem Applikatortip ohne weitere Verarbeitung auf die Oberfläche aufgetragen. Er breitet sich von selbst über den größten Teil der Fläche aus.
							</p>
						</div>
					</Grid>
				</div>
			</Grid>

			<Spacer />
				

			<h2>
				Scherhaftfestigkeit an Dentin²<br />
				[MIDa]
			</h2>
			<img src={scherhaftigkeitDesDentingGraphic} alt="Scherhaftigkeit des Denting" style={{width: "100%"}} />

			<SmallSpacer />
			
			<p>
				Wasserkontamination
			</p>
			<img src={wasserkontaminationGraphic} alt="Scherhaftigkeit des Denting" style={{width: "100%", maxWidth: "270px"}} />

			<Spacer />
			<Footnotes>
				<p>
					¹ Keine eingetragenes Warenzeichen von Dentsply Sirona, Inc.
				</p>
				<p>
					² ?
				</p>
			</Footnotes>
		</div>
	)
}

export default RestaurationenKompositHerausforderung2
