import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import Obturation from './Obturation'
import SmartLitePro from './SmartLitePro'
import SmartLiteProTruNatomy from './SmartLiteProTruNatomy'
import SmartLiteProWaveOne from './SmartLiteProWaveOne'
import SpuelungAktivierung from './SpuelungAktivierung'
import TemporaereEinlage from './TemporaereEinlage'

interface Props {
	
}

const Endo = (props: Props) => {

	let { url } = useRouteMatch();

	return (
		<Switch>
			<Route path={`${url}/smartlitepro/waveone`}>
				<SmartLiteProWaveOne />
			</Route>
			<Route path={`${url}/smartlitepro/trunatomy`}>
				<SmartLiteProTruNatomy />
			</Route>
			<Route path={`${url}/smartlitepro`}>
				<SmartLitePro />
			</Route>
			<Route path={`${url}/spuelungaktivierung`}>
				<SpuelungAktivierung />
			</Route>
			<Route path={`${url}/temporaereeinlage`}>
				<TemporaereEinlage />
			</Route>
			<Route path={`${url}/obturation`}>
				<Obturation />
			</Route>
			<Route path={`${url}/blabla`}>
				blabla
			</Route>
		</Switch>
	)
}

export default Endo
