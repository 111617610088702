import Grid from '../../components/grid'
import Section from '../../components/section'

import mesialOkklusalGraphic from './img/mesial-okklusal.png'
import distalOkklusalGraphic from './img/distal-okklusal.png'
import mesialOkklusalDistalGraphic from './img/mesial-okklusal-distal.png'
import fehlenderHoeckerGraphic from './img/fehlender-hoecker.png'
import fehlenderNachbarzahnGraphic from './img/fehlender-nachbarzahn.png'
import fehlstellungDesZahnsGraphic from './img/fehlstellung-des-zahns.png'
import isloationBeimStumpfaufbauGraphic from './img/isloation-beim-stumpfaufbau.png'
import palodentv3Graphic from './img/palodentv3.png'
import palodent360Graphic from './img/palodent360.png'
import Spacer from '../../components/spacer'

interface Props {
	
}

const RestaurationenKompositHerausforderung1 = (props: Props) => {
	return (
		<div>
			<Section>
				<h1>Palodent® V3 oder Palodent 360</h1>

				<Grid gridTemplateColumns="5fr 7fr">

					<div className="small-text">
						<p>
							<strong>
								Herausforderung
							</strong>
						</p>
						<p>
							Herstellung anatomisch korrekter Kontaktpunkte und Konturen
						</p>
						<p>
							<strong>Lösung</strong> Palodent® V3 oder
							Palodent 360
						</p>

						<ul className="small-text">
							<li>
								Dichte gingivale Versiegelung
							</li>
							<li>
								Weniger Überschuss, weniger Finieren
							</li>
							<li>
								Einfache Handhabung
							</li>
						</ul>
					</div>

					<div>
						<Grid gridTemplateColumns="1fr 1fr" style={{textAlign: 'right'}}>
							<div>
								<img src={palodentv3Graphic} alt="Palodent v3" style={{width: "100%", height: 175, objectFit: "contain", objectPosition: "right center"}}/>
								<p className="small-text">
									Palodent® V3
								</p>
							</div>
							<div>
								<img src={palodent360Graphic} alt="Palodent 360" style={{width: "100%", height: 175, objectFit: "contain", objectPosition: "right center"}}/>
								<p className="small-text">
									Palodent 360
								</p>
							</div>
						</Grid>
					</div>
				</Grid>

				<Spacer />

				<p>
					Verwenden Sie das Palodent® V3 System für:
				</p>
				<Grid gridTemplateColumns="repeat(3, 1fr)" style={{alignItems: "center", maxWidth: 540}}>
					<p style={{alignSelf: "flex-start"}} className="small-text">
						<strong>
							Mesial-okklusal
						</strong>
					</p>
					<p style={{alignSelf: "flex-start", marginTop: 0}}  className="small-text">
						<strong>
							Distal-okklusal
						</strong>
					</p>
					<p style={{alignSelf: "flex-start", marginTop: 0}}  className="small-text">
						<strong>
							Mesial-okklusal-distal
						</strong>
					</p>
					<img src={mesialOkklusalGraphic} alt="" style={{width: 128}}/>
					<img src={distalOkklusalGraphic} alt="" style={{width: 128}}/>
					<img src={mesialOkklusalDistalGraphic} alt="" style={{width: 128}}/>
				</Grid>
				
			</Section>

			<Spacer />

			<p>
				Verwenden Sie das Palodent® 360 System für:
			</p>
			<Grid gridTemplateColumns="repeat(4, 1fr)">
				<div>
					<h3>Fehlender Höcker</h3>
					<img src={fehlenderHoeckerGraphic} alt="" style={{width: 127}}/>
				</div>
				<div>
					<h3>Fehlender Nachbarzahn</h3>
					<img src={fehlenderNachbarzahnGraphic} alt="" style={{width: 127}}/>
				</div>
				<div>
					<h3>Fehlstellung des Zahnes</h3>
					<img src={fehlstellungDesZahnsGraphic} alt="" style={{width: 127}}/>
				</div>
				<div>
					<h3>Isolation beim Stumpfaufbau</h3>
					<img src={isloationBeimStumpfaufbauGraphic} alt="" style={{width: 127}}/>
				</div>
			</Grid>
			<Spacer />
		</div>
	)
}

export default RestaurationenKompositHerausforderung1
