import Grid from '../../components/grid'

import desensibilisierung1Graphic from './img/desensibilisierung_01.png'
import desensibilisierung2Graphic from './img/desensibilisierung_02.png'
import desensibilisierung3Graphic from './img/desensibilisierung_03.png'

interface Props {
	
}

const Desensibilisierung = (props: Props) => {

	return (
		<div>
			<h1>
				SiroLaser Blue – Desensibilisierung
			</h1>
			<div>
				<ul>
					<li>
						Schmerzfreie und schnelle Behandlung
					</li>
					<li>
						Kein Einsatz von Anästhesie erforderlich
					</li>
					<li>
						Sofortige Befreiung von Sensibilitätsstörungen 
					</li>
				</ul>
			</div>
			<Grid gridTemplateColumns="repeat(3, 1fr)" style={{gap: 150}}>
				<div>
					<img src={desensibilisierung1Graphic} alt="Desensibilisierung 1"  style={{width: 214}} />
					<p className="small-link">
						Erster Prämolarer mit freiliegendem Dentin
					</p>
				</div>
				<div>
					<img src={desensibilisierung2Graphic} alt="Desensibilisierung 2"  style={{width: 214}} />
					<p className="small-link">
						Taktile Stimulation mit der WHO PA Sonde
					</p>
				</div>
				<div>
					<img src={desensibilisierung3Graphic} alt="Desensibilisierung 3"  style={{width: 214}} />
					<p className="small-link">
						Bestrahlung der Zahnfläche mit dem SIROLaser Advance
					</p>
				</div>

			</Grid>
		</div>
	)
}

export default Desensibilisierung
