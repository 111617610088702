import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Button from '../../components/button'

interface Props {
	
}

const TwoColumnSwitch = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	@media (max-width: 800px) {
		flex-wrap: wrap;
		gap: 40px;
	}
`

const TwoColumnOption = styled.div`
	text-align: center;
	/* width: 50%; */

	p {
		padding: 55px 50px;
		height: 182px;
		display: flex;
		align-items: center;

		@media (max-width: 800px) {
			height: auto;
			border: none !important;
			padding: 20px;
		}
	}

`

const SmartLitePro = (props: Props) => {
	return (
		<div>
			<h1 style={{textAlign: "center"}}>Reziprok oder rotierend?</h1>
			<TwoColumnSwitch>
					<TwoColumnOption>
						<p>Zeitsparend <br /> & einfach <br /> im Handling</p>
						<Link to="/endo/smartlitepro/waveone">
							<Button buttonStyle="secondary">
								reziprok
							</Button>
						</Link>			
					</TwoColumnOption>
					<TwoColumnOption>
						<p style={{borderLeft: "1px solid white"}}>Dentinschonend</p>
						<Link to="/endo/smartlitepro/trunatomy">
							<Button>
								rotierend
							</Button>
						</Link>
					</TwoColumnOption>
			</TwoColumnSwitch>
		</div>
	)
}

export default SmartLitePro
