import React from 'react'
import Grid from '../../components/grid'

import waveonGraphic from './img/WaveGold-Grafik.png'
import goldGraphic from './img/Gold.png'
import goldGliderGraphic from './img/GoldGlider.png'
import readySteelGraphic from './img/ReadyStreel.png'
import motorGraphic from './img/motor-graphic.png'
import Section from '../../components/section'
import Spacer from '../../components/spacer'
import styled from 'styled-components'
import arrowIcon from './img/arrow.svg'

interface Props {
	
}

const Arrow = styled.div`
	position: absolute;
	width: 16px;
	height: 16px;
	right: 30px;
	background-image: url(${arrowIcon});
	background-size: contain;
	background-repeat: no-repeat;
	top: 65px;
`

const SmartLiteProWaveOne = (props: Props) => {
	return (
		<div>
			<Section>
				<h1>Vorteilhafte Eigenschaften/Nutzen</h1>
				
				<ul>
					<li>Zeitsparend</li>
					<li>Patientenfreundlich</li>
					<li>Einfachheit für Sie, Sicherheit für Ihre Patienten</li>
					<li>Komplettes Behandlungskonzept</li>
				</ul>

				<p style={{maxWidth: 'none'}}>
					Von der Wurzel bis zur Krone: Eine vollständige reziproke Lösung!
				</p>
			</Section>



			<h1>
				WaveOne® Gold
			</h1>
			<Grid gridTemplateColumns="7fr 5fr">
				<div>

					<p>
						Erhöhte Patientensicherheit bei gleichzeitiger Reduktion der Aufbereitungszeit für eine Vielzahl von Fällen.
					</p>
					<p>
						Verbesserte Design-Eigenschaften, optimiert durch führende Endodontologen.
					</p>
					<p>
						Teil eines Komplettsystems inklusive passgenauer Obturationsprodukte, abgestimmt auf die durch WaveOne Gold Feilen aufbereitete Form.
					</p>
					<p>
						Die WaveOne Gold Produkt-Familie bietet eine umfassende Behandlungslösung zur Förderung von Sicherheit und vorhersagbarer Form.
					</p>
				</div>
				<div>
					<img src={waveonGraphic} alt=""/>
				</div>
			</Grid>

			<Spacer />

			<h2>Die goldene reziproke Sequenz</h2>
			<Grid gridTemplateColumns="repeat(3, 1fr)">
				<div style={{position: "relative"}}>
					<h3>Scouting</h3>
					<p>ReadySteel®</p>
					<img src={readySteelGraphic} alt="ready steel" />
					<Arrow />
				</div>
				<div style={{position: "relative"}}>
					<h3>Gleitpfad</h3>
					<p>WaveOne® Gold Glider</p>
					<img src={goldGliderGraphic} alt="Gold glider" />
					<Arrow />
				</div>
				<div>
					<h3>Aufbereitung</h3>
					<p>WaveOne® Gold</p>
					<img src={goldGraphic} alt="Gold glider" />
				</div>
			</Grid>

			<Spacer />

			<Grid gridTemplateColumns="8fr 4fr">
				<div>
					<h3>
						Motor und Längenmessung
					</h3>
					<p>
						X-Smart IQ® Endo-Motor mit dem Propex IQ® Apex Locator
					</p>
					<h3>
						Angetrieben mit der original reziproken Bewegung
					</h3>
					<p>
						Weil jeder Winkel, jede Beschleunigung, jede Geschwindigkeit wichtig ist, um ein Ergebnis auf höchstem Niveau zu erzielen. Unsere Motoren bieten die original reziproke Antriebstechnik für WaveOne Gold®.
					</p>
				</div>
				<div>
					<img src={motorGraphic} alt="motor graphic" style={{marginLeft: '80px', width:140, marginTop: 40}}/>
				</div>
			</Grid>

			<Spacer />
		</div>
	)
}

export default SmartLiteProWaveOne
