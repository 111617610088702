import { CalloutLink } from '../../components/callout'
import Footnotes from '../../components/footnotes'
import Grid from '../../components/grid'
import MoreLink from '../../components/morelink'
import Section from '../../components/section'
import Spacer from '../../components/spacer'

import restaurationenKompositGraphic from './img/restaurationen-komposit.png'

interface Props {
	
}

const RestaurationenKomposit = (props: Props) => {
	return (
		<div>
			<Section>
				<h1>Klasse II-Füllung</h1>

				<img src={restaurationenKompositGraphic} style={{width: "100%"}} alt="Restaurationen Komposit Grafik"/>

				<Grid gridTemplateColumns="repeat(3, 1fr)">
					<CalloutLink to="/direkte/restauration-komposit-herausforderung1">
						<p>
							70% der Zahnärzte finden, die Kontaktpunktherstellung sei der schwierigste Teil einer Klasse II Restauration.¹
						</p>
						<MoreLink to="/direkte/restauration-komposit-herausforderung1">
							Mehr erfahren
						</MoreLink>
					</CalloutLink>
					<CalloutLink to="/direkte/restauration-komposit-herausforderung2">
						<p>
							97% der Zahnärzte sagen, dass zu feuchtes oder übertrocknetes Dentin manchmal einen Einfluss auf den Adhäsivverbund ihrer Füllungen haben kann.²
						</p>
						<MoreLink to="/direkte/restauration-komposit-herausforderung2">
							Mehr erfahren
						</MoreLink>
					</CalloutLink>
					<CalloutLink to="/lichthaertung/smartlitepro">
						<p>
							Bei vielen Lampen erreichen weniger als 35% der angegebenen Leistung den Kavitätenboden eines approximalen Kastens.³
						</p>
						<MoreLink to="/lichthaertung/smartlitepro">
							Mehr erfahren
						</MoreLink>
					</CalloutLink>
					<CalloutLink to="/direkte/restauration-komposit-herausforderung4">
						<p>
							82% aller Zahnärzte verwenden zur Verbesserung der Randdichtigkeit bei Klasse II Füllungen einen Liner.⁴
						</p>
						<MoreLink to="/direkte/restauration-komposit-herausforderung4">
							Mehr erfahren
						</MoreLink>
					</CalloutLink>
					<CalloutLink to="/direkte/restauration-komposit-herausforderung5">
						<p>
							74% der Zahnärzte beanstanden die Verarbeitungseigenschaften ihres Komposits in Bezug auf Adaptierbarkeit und Klebrigkeit.⁵
						</p>
						<MoreLink to="/direkte/restauration-komposit-herausforderung5">
							Mehr erfahren
						</MoreLink>
					</CalloutLink>
					<CalloutLink to="/direkte/restauration-komposit-herausforderung6">
						<p>
							Zahnärzte können mit einer guten Finiertechnik die Langlebigkeit von Klasse-II-Füllungen erhöhen.⁶
						</p>
						<MoreLink to="/direkte/restauration-komposit-herausforderung6">
							Mehr erfahren
						</MoreLink>
					</CalloutLink>
				</Grid>
			</Section>

			<Spacer />

			<Footnotes>
				<p>
					¹  DentalTown (2012). Restorative Dentistry. Monthly Poll: What is the most challenging part of a Class II Restoration?
				</p>
				<p>
					²  Quelle: Exevia Dental Shuttle Q1/2015, n=702, GDP’s Italien, Frankreich, Deutschland.
				</p>
				<p>
					⁴  Council on Scientific Affairs of the American Dental Association. Spring 2009;4(2)
				</p>
				<p>
					⁵  Dentsply Sirona Restorative, Anwenderumfrage 2015 (297 Zahnärzte in Europa)
				</p>
				<p>
					⁶  Christensen, G J. (2014). Simplifying your Class II Composite Finishing Technique. Clinicians Report, Colume 7 Issue 4
				</p>
			</Footnotes>
		</div>
	)
}

export default RestaurationenKomposit
