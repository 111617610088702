import React from 'react'
import styled from 'styled-components';
// import { Header } from '../../components/header'
// import Endo from './endo/Endo';
// import Icon from './HomeGraphic';
// import graphic from './HomeGraphic@2x.png';
import HomeGraphic from './HomeGraphic'

interface Props {
	
}

const ImageWrapper = styled.div`
	width: calc(100% + 42px + 42px);
	/* width: 100%; */
	overflow: scroll;
	scrollbar-width: none;
	padding-left: 42px;
	padding-right: 42px;
	margin-left: -42px;
	padding-bottom: 40px;

	::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
	}
	/* Optional: show position indicator in red */
	::-webkit-scrollbar-thumb {
			background: transparent;
	}

`

const Home = (props: Props) => {
	return (
		<div>
			<ImageWrapper>
				<HomeGraphic></HomeGraphic>
			</ImageWrapper>
		</div>
	)
}

export default Home
