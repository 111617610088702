import { useEffect } from 'react';
import './App.css';
import { Route, HashRouter as Router, Switch, Link, useLocation } from 'react-router-dom'
import Home from './pages/home/Home';
import GlobalStyle from './components/styles/GlobalStyle';
import Endo from './pages/endo/Endo';
import { Header } from './components/header';
import styled from 'styled-components';
import Button from './components/button';
import Lichthaertung from './pages/lichthaertung';
import Direkte from './pages/direkte';
import BackButton from './components/back-button';
import Indirekte from './pages/indirekte';
import Sirolaser from './pages/sirolaser';

const GlobalWrapper = styled.div`
  @media (min-width: 1500px) {
    max-width: 1366px;
    margin-inline: auto;
    width: 100%;
  }
`

const MainContent = styled.div`
  min-height: calc(100vh - 80px - 50px - 40px - 40px - 40px);
  display: flex;
  /* align-items: center; */

  > * {
    width: 100%;
  }
`

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    console.log('scrolltop')
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {

  return (
    <GlobalWrapper>
      <GlobalStyle />
      <Router>
        <ScrollToTop />
        <Header />
        <MainContent>
          <Switch>
            <Route exact path="/">
                <Home />
            </Route>
            <Route path="/about">
              <div>
                <h1>Page2</h1>
                <Link to="/">
                  <Button buttonStyle="secondary">
                    Back
                  </Button>
                </Link>
              </div>
            </Route>
            <Route path="/endo">
                <Endo />
            </Route>
            <Route path="/lichthaertung">
                <Lichthaertung />
            </Route>

            <Route path="/direkte">
                <Direkte />
            </Route>

            <Route path="/indirekte">
                <Indirekte />
            </Route>

            <Route path="/sirolaser">
                <Sirolaser />
            </Route>
          </Switch>

        </MainContent>

        <BackButton />
      </Router>
    </GlobalWrapper>
  );
}

export default App;
