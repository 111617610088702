import Footnotes from '../../components/footnotes'
import Grid from '../../components/grid'
import Spacer from '../../components/spacer'

import prothetik1Graphic from './img/prothetik_01.png'
import prothetik2Graphic from './img/prothetik_02.png'
import prothetik3Graphic from './img/prothetik_03.png'

interface Props {
	
}

const CerecProthetik = (props: Props) => {

	return (
		<div>
			<div>
				<h1>
					SiroLaser Blue – CEREC / Prothetik
				</h1>
				<Grid gridTemplateColumns="7fr 5fr">
					<div>
						<Grid gridTemplateColumns="auto 1fr"  style={{marginBottom: 20}}>
							<img src={prothetik1Graphic} alt="prothetik 1" width="175" height="131"/>
							<div>
								<p>
									Die mesiale Papille sowie eine kleine Überlappung im disto-labialen Bereich verhindern eine optimale digitale Abformung.
								</p>
							</div>
						</Grid>
						<Grid gridTemplateColumns="auto 1fr">
							<img src={prothetik2Graphic} alt="prothetik 2" width="175" height="131"/>
							<div>
								<p>
									Die mesiale Papille sowie eine kleine Überlappung im disto-labialen Bereich verhindern eine optimale digitale Abformung.
								</p>
							</div>
						</Grid>
					</div>

					<Grid gridTemplateColumns="auto 1fr">
						<img src={prothetik3Graphic} alt="prothetik 2" width="176" height="131"/>
						<div>
							<p>
								Digitaler Abdruck mit Cerec.
							</p>
						</div>
					</Grid>
				</Grid>

				<Spacer />

				<Footnotes>
					<p>
						Quelle: Laser – international magazine of laser dentistry – SIROLaser Factbook; “Use of a Diode Laser for Gingival Troughing in Conservative and Prosthetic Dentistry”, Dr Almut Marsch
					</p>
				</Footnotes>
			</div>
		</div>
	)
}

export default CerecProthetik
