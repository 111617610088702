import { Link, LinkProps } from 'react-router-dom'
import styled from 'styled-components'

const MoreLinkComonent = styled.a`
	font-size: 16px;
	font-weight: var(--font-weight__medium);
	color: inherit;
	text-decoration: none;

	&:hover {
		color: var(--color__primary)
	}
`

const MoreLink : React.FC<LinkProps>= ( { to, children }) => {
	return (
		<Link to={to} style={{color: 'inherit', textDecoration: 'none'}}>
			<MoreLinkComonent>
				{children}
			</MoreLinkComonent>
		</Link>
	)
}
export default MoreLink
