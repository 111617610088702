import { Route, Switch, useRouteMatch } from 'react-router-dom'
import AquasilUltra from './AquasilUltra';
import CalibraCements from './CalibraCements';
import IntegrityMultiCure from './IntegrityMultiCure';
import SiroLaser from './SiroLaser';

interface Props {
	
}

const Indirekte = (props: Props) => {

	let { url } = useRouteMatch();

	return (
		<Switch>
			<Route path={`${url}/sirolaser`}>
				<SiroLaser />
			</Route>
			<Route path={`${url}/aquasilultra`}>
				<AquasilUltra />
			</Route>
			<Route path={`${url}/integritymulticure`}>
				<IntegrityMultiCure />
			</Route>
			<Route path={`${url}/calibracements`}>
				<CalibraCements />
			</Route>
		</Switch>
	)
}

export default Indirekte
