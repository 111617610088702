import Footnotes from '../../components/footnotes'
import Grid from '../../components/grid'
import Spacer from '../../components/spacer'

import schmerztherapie1Graphic from './img/schmerztherapie_01.png'
import schmerztherapie2Graphic from './img/schmerztherapie_02.png'

interface Props {
	
}

const Schmerztherapie = (props: Props) => {

	return (
		<div>
			<div>
				<h1>
					SiroLaser Blue – Schmerztherapie
				</h1>
				<Grid gridTemplateColumns="7fr 5fr">
					<div>
						<ul>
							<li>
								Schmerztherapie (allgemein)
							</li>
							<li>
								Wundbehandlung/ -heilung
							</li>
							<li>
								Herpes und Aphthen
							</li>
							<li>
								Kein Einsatz von Anästhesie notwendig
							</li>
							<li>
								Sofortige Befreiung von Schmerzen
							</li>
						</ul>
					</div>

					<div>
						<Grid gridTemplateColumns="auto 1fr" style={{marginBottom: 40}}>
							<img src={schmerztherapie1Graphic} alt="Aphten nach der Behandlung" width="176" height="131"/>
							<div>
								<p className="small-text">
									Aphten:<br />
									Vor der Behandlung
								</p>
							</div>
						</Grid>
						<Grid gridTemplateColumns="auto 1fr">
							<img src={schmerztherapie2Graphic} alt="Vor der Behandlung" width="176" height="131"/>
							<div>
								<p className="small-text">
									Aphten:<br />
									Direkt nach der 
									Behandlung mit dem 
									Diodenlaser
								</p>
							</div>
						</Grid>
					</div>
				</Grid>
			</div>

			<Spacer />
			
			<Footnotes>
				<p>
					Quelle: Dr. med. dent. Helmut Götte
				</p>
			</Footnotes>
		</div>
	)
}

export default Schmerztherapie
