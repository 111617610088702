import Grid from '../../components/grid'

import paradontologieGraphic from './img/paradontologie.png'

interface Props {
	
}

const Paradontologie = (props: Props) => {

	return (
		<div>
			<div>
				<h1>
					SiroLaser Blue - Paradontologie
				</h1>
				<Grid gridTemplateColumns="9fr 3fr">
					<div>
						<ul>
								<li>
									Ergänzend zur konventionellen Therapie
								</li>
								<li>
									Keimreduktion in der Parodontaltasche
								</li>
								<li>
									Entfernung des Granulationsgewebes und Deepithelisierung
								</li>
								<li>
									Periimplantitis (Parodontitis am Implantat)
								</li>
								<li>
									Vermeidung von Antibiotika
								</li>
								<li>
									Aufbau einer gesunden Parodontalflora
								</li>
								<li>
									Zusätzliche Liquidation zur Kassen-PA
								</li>
						</ul>
					</div>
					<div style={{textAlign: 'right', width: "100%"}}>
						<img src={paradontologieGraphic} alt="Paradontologie" style={{width: 185}}  />
					</div>
				</Grid>
			</div>
		</div>
	)
}

export default Paradontologie
