import Grid from '../../components/grid'
import Section from '../../components/section'
import Spacer, { SmallSpacer } from '../../components/spacer'

import strahlenprofilGraphic from './img/strahlenprofil.svg'
import smartliteproGraphic from './img/smartlitepro.png'
import wettbewerberGraphic from './img/wettbewerber.png'
import mundzugangGraphic from './img/mundzugang.png'
import polymerisationslampeGraphic from './img/polymerisationslampe.png'

interface Props {
	
}

const SmartLitePro = (props: Props) => {
	return (
		<div>
			<Section>
				<h1>SmartLite Pro</h1>
				<p>
					Zuverlässige Lichthärtung selbst über größere Abstände dank exzellent gebündeltem Lichtstrahl, großem Lichtaustrittsfenster (10 mm) und gleichmäßiger Lichtverteilung (1.250 mW/cm²).
				</p>
				<Spacer />

				<p className="small-text">
					Strahlenprofil der SmartLite® Pro im Vergleich zu 3 anderen Lampen zeigen die Lichtverteilung an der Spitze des Lichtleiters.¹
				</p>
				<SmallSpacer />
				<img src={strahlenprofilGraphic} alt="Strahlenprofil" style={{width: "100%"}} />
				<SmallSpacer />
				<p className="small-text">
				Alle Bilder wurden im Abstand von 0 mm unter identischen Bedingungen aufgenommen und zeigen das ungefilterte Strahlenprofil aller Wellenlängen von blauem und violettem Licht (350-550 nm).
				</p>
			</Section>


			<Spacer />


			<Grid gridTemplateColumns="1fr 1fr 2fr">
				<div>
					<img src={smartliteproGraphic} alt="SmartLite Pro" style={{width: "100%", height: 225, objectFit: "cover"}}/>
					<p className="small-text">
						SmartLite® Pro
					</p>
				</div>
				<div>
					<img src={wettbewerberGraphic} alt="Wettbewerber" style={{width: "100%", height: 225, objectFit: "cover"}}/>
					<p className="small-text">
						Beispiel: Wettbewerber
					</p>
				</div>
				<div>
					<img src={mundzugangGraphic} alt="Mundzugang"  style={{width: "100%", height: 225, objectFit: "cover"}}/>
					<p className="small-text">
						SmartLite® Pro: Gebündelter Lichtstrahl für verlässliche Polymerisation über größere Abstände
					</p>
				</div>
			</Grid>

			<Spacer />

			<h1>
				Was macht die SmartLite Pro® zu mehr als nur einer Polymerisationslampe?
			</h1>

			<SmallSpacer />

			<Grid gridTemplateColumns="repeat(3, 1fr)">
				<div  style={{textAlign: "center", alignSelf:"center"}}>
					<img src={polymerisationslampeGraphic} alt="polymerisationslampeGraphic" style={{width: "60%"}}/>
				</div>
				<div>
					<h3>
						Vielseitig
					</h3>
					<p>
						Austauschbare um 360° drehbare Lichtaufsätze mit zusätzlichem Transilluminations-Tip zur Erkennung von Frakturen und Karies sowie zur Ausleuchtung endodontischer Zugangskavitäten.
					</p>
					<h3>
						Multifunktional
					</h3>
					<p>
						Ladestation mit eingebautem Radiometer, Ladeport für die zweite Batterie und Platz für zusätzliche Aufsätze.
					</p>
				</div>

				<div>
					<h3>
						Ganzmetall-Gehäuse
					</h3>
					<p>
						Edelstahl und eloxiertes Aluminium in medizinischer Qualität.
					</p>
					<h3>
						Modernste Optik
					</h3>
					<p>
						Optimiertes Design mit 4 LEDs für gleichmäßige, gebündelte Lichtverteilung.
					</p>
					<h3>
						Zuverlässige klinische Ergebnisse
					</h3>
					<p>
						10 mm großes aktives Lichtfenster deckt auch größere Füllungen ab.
					</p>
				</div>
			</Grid>
		</div>
	)
}

export default SmartLitePro
