import React from 'react'
import { Route, Switch } from 'react-router'
import styled from 'styled-components'
import icon from './back-button-icon.svg';

interface Props {
	
}

const BackButtonComponent = styled.button`
	font-size: 16px;
	font-weight: var(--font-weight__medium);
	color: white;
	background: transparent;
	border: none;
	padding: 0;
	margin-top: 50px;

	&::before {
		content: " ";
		width: 16px;
		height: 16px;
		display: inline-block;
		background-image: url(${icon});
		background-size: contain;
		margin-right: 10px;
		margin-bottom: -2px
	}

	&:focus {
		outline: none;
	}
`

const BackButton = (props: Props) => {
	return (
		<Switch>
				<Route exact path="/">
				</Route>
				<Route path="/">
					<BackButtonComponent onClick={ () => window.history.back() }>
						Zurück
					</BackButtonComponent>
				</Route>
		</Switch>
	)
}

export default BackButton
