import styled from "styled-components";

const Section = styled.div`
	min-height: calc(100vh - 40px - 80px - 40px );
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
`

export default Section