import React from 'react'
import Callout from '../../components/callout'
import Footnotes from '../../components/footnotes'
import Grid from '../../components/grid'
import Section from '../../components/section'
import { SmallSpacer } from '../../components/spacer'

import guttaSmartGraphic from './img/guttasmart.png'
import ahPlusJetStarterKitGraphic from './img/AH-Plus-Jet-StarterKit.png'

interface Props {
	
}

const Obturation = (props: Props) => {
	return (
		<div>
			<Section>
				<h1>Obturation</h1>

				<p>
					Wurzelkanalfüllungen ohne Hohlräume verbessern signifikant das Ergebnis einer primären Wurzelkanalbehandlung.¹
				</p>
				<p>
					Wurzelkanal-Sealer auf Epoxid-Basis sind die etabliertesten und am meisten untersuchten Sealer weltweit. Sie können uneingeschränkt für die klinische Anwendung empfohlen werden.²
				</p>
			</Section>

			
			<Grid gridTemplateColumns="9fr 5fr">
				<h1>
					Gutta-Smart™ und AH Plus®
				</h1>
				<div style={{alignSelf: 'flex-end'}}>
					<Callout calloutStyle="info">
						Passende Guttapercha für
						WaveOne® Gold & TruNatomy™
					</Callout>
				</div>
			</Grid>

			<SmallSpacer />

			<Grid gridTemplateColumns="7fr 5fr">
				<div>
					<p>
						Die warme vertikale Obturationslösung
					</p>
					<ul>
						<li>
							Effiziente Gesamtlösung: Gleiche Conform Fit™ Guttapercha in den Guttaperchastiften und Gutta-Smart™ Kartuschen
						</li>
						<li>
							Behandlungskomfort durch die Conform Fit Guttapercha mit niedrigerer Fließtemperatur
						</li>
						<li>
							Patientenkomfort: Latexfreie Guttapercha
						</li>
						<li>
							Überlegene apikale Passung bietet einen akkuraten Tug Back
						</li>
						<li>
							Leistungsstarke Akkus
						</li>
						<li>
							Anwenderfreundlich durch stoßfeste, kabellose Handstücke
						</li>
					</ul>
				</div>
				<div>
					<img src={guttaSmartGraphic} alt="Gutta Smart"/>
				</div>
			</Grid>

			<Grid gridTemplateColumns="5fr 7fr">
				<img src={ahPlusJetStarterKitGraphic} alt="AH Plus"/>
				<ul>
					<li>
						Auf Epoxid-Harz-Basis – gehört zu den am meisten untersuchten Sealern weltweit
					</li>
					<li>
						Einfache und saubere Anwendung
					</li>
					<li>
						Direkte, präzise Platzierung im Wurzelkanal
					</li>
					<li>
						Hervorragende Versiegelung und Rötgensichtbarkeit
					</li>
					<li>
						Hohe Biokompatibilität und Thermostabilität
					</li>
				</ul>
			</Grid>

			<Footnotes>
				<p>
					¹ Ng, Mann, Rahbaran, Lewsey & Gulabivala, Unit of Endodontology, UCL Eastman Dental Institute, University College London, London; Department of Medical Statistics, London School of Tropical Medicine and Hygiene, London; and Clinical effectiveness Unit, The Royal College of Surgeons of England, London, UK.
				</p>
				<p>
					² Schaefer, E: Evaluation of contemporary root canal filling materials, ZM 93:1, 24-28. 2003. Article in German
				</p>
			</Footnotes>
		</div>
	)
}

export default Obturation
