import Section from '../../components/section'
import Spacer from '../../components/spacer'

import calibraCementeGraphic from './img/calibra-cemente.png';
import calibraCementeChartGraphic from './img/calibra-cements-graphics.svg';
import zementauswahlGraphic from './img/calibracements-zementauswahl.png';
import calibraCementsFlowchartGraphic from './img/calibra-cements-flowchart.svg';

import Footnotes from '../../components/footnotes';

interface Props {
	
}

const CalibraCements = (props: Props) => {
	return (
		<div>
			<Section>
				<h1>
					97% der Zahnärzte sagen, dass saubere Überschussentfernung wichtig für eine gesunde Gingiva ist.¹
				</h1>
				<h1>
					Vorteilhafte Eigenschaften & Nutzen
				</h1>
				<ul>
					<li>
						Langlebigkeit
					</li>
					<li>
						Robuste Haftfestigkeit
					</li>
					<li>
						Effizient – wenig Produkte, einfache Auswahl
					</li>
					<li>
						Ästhetik
					</li>
				</ul>
			</Section>

			<h1>Calibra® Zemente</h1>
			<p>
				Die Calibra® Zemente erleichtern die Überschussentfernung erheblich. Calibra® ermöglicht eine gründliche und effektive Entfernung der Zementreste dank des Anhärtens von bis zu 10 Sekunden und einer verlängerten Gelphase.²
			</p>

			<img src={calibraCementeGraphic} alt="Calibra Cementes" width="529"/>

			<Spacer />

			<img src={calibraCementeChartGraphic} alt="Calibra Cementes Chart"/>

			<Spacer />

			<h1>
				Zementauswahl leicht gemacht
			</h1>
			<img src={zementauswahlGraphic} alt="Zementsauswahl"/>

			<Spacer />

			<img src={calibraCementsFlowchartGraphic} alt="Zementsauswahl Flowchart" width="100%"/>

			<Spacer />

			<Footnotes>
				<p>
					¹ The Key Group Inc. Omnibus Survey, March 2015 n=174 (Resin Cement Users)
				</p>
				<p>
					² Für die Überschussentfernung werden Monowellen-LED (470 nm) oder konventionelle Halogenleuchten empfohlen. Leistungsstarke duale oder Breitspektrumslampen können zu vorzeitigem Härten des überschüssigen Zements führen. Es wird empfohlen die Interaktion zwischen Polymerisationslampe und Zement vor der klinischen Verwendung zu prüfen.
				</p>
				<p>
					³ Keine eingetragenes Warenzeichen von Dentsply Sirona, Inc.
				</p>
			</Footnotes>
		</div>
	)
}

export default CalibraCements
