import styled from 'styled-components'

const Footnotes = styled.div`
	font-size: 12px;
	line-height: 14px;
	/* max-width: 540px; */
	
	p {
		font-size: 1em;
		line-height: 14px;
		max-width: none;
	}
`

export default Footnotes
