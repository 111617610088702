import styled from 'styled-components'

interface Props {
	buttonStyle?: string
}

const Button = styled.span<Props>`
	display: inline-block;
	background-color: ${props => props.buttonStyle === 'secondary' ? 'var(--color__secondary)' : 'var(--color__primary)'};
	color: ${props => props.buttonStyle === 'secondary' ? 'var(--color__on_secondary)' : 'var(--color__on_primary)'};
	font-size: 20px;
	line-height: 24px;
	padding: 15px 20px 15px;
	cursor: pointer;
	font-weight: var(--font-weight__medium);

	@media (max-width: 800px) {
		font-size: 18px;
	}
`

export default Button


export const ButtonGroup = styled.span<Props>`
	display: flex;
	gap: 20px;
	flex-wrap: wrap;
	margin-bottom: 20px;
`
