import React from 'react'
import Grid from '../../components/grid'

import truNatomyGraphic from './img/TruNatomy-Grafik.png'
import truNatomyAufbereitung from './img/tru-natomy-aufbereitung.png'
import truNatomyGleitpfad from './img/tru-natomy-gleitpfad.png'
import truNatomyKanaleingang from './img/tru-natomy-kanaleingang.png'

import traditionelleEndodontischeKavität from './img/rraditionelle-endodontische-kavitaet.png'
import dentinschonendeEndodontischeKavität from './img/dentinschonendeEndodontischeKavitaet.png'
import trunamomyDentin from './img/trunamomy-dentin.svg'
import trunamomyDentinLegende from './img/trunamoy-dentin-legende.svg'

import Section from '../../components/section'
import Spacer from '../../components/spacer'
import Footnotes from '../../components/footnotes'
import styled from 'styled-components'
import arrowIcon from './img/arrow.svg'

interface Props {
	
}

const Arrow = styled.div`
	position: absolute;
	width: 16px;
	height: 16px;
	right: 30px;
	background-image: url(${arrowIcon});
	background-size: contain;
	background-repeat: no-repeat;
	top: 50px;
`

const SmartLiteProTruNatomy = (props: Props) => {
	return (
		<div>
			<Section>
				<h1>Vorteilhafte Eigenschaften/Nutzen</h1>
				
				<ul>
					<li>Die natürliche Wurzelkanalanatomie respektieren</li>
					<li>Dentinschonend</li>
					<li>Komplettes Behandlungskonzept</li>
				</ul>
			</Section>

			<h1>
				TruNatomy™
			</h1>
			<Grid gridTemplateColumns="7fr 5fr">
				<div>

					<p>
					Viele Feilensysteme sind nicht vollumfänglich und komplett aufeinander abgestimmt, TruNatomy™ bietet ein ineinandergreifendes Konzept mit den folgenden Vorteilen:
					</p>
					<ul>
						<li>
							33% schlankerer NiTi Draht und exzentrischer Feilenquerschnitt für einen effektiven Debrisabtransport
						</li>
						<li>
							Spezielle Wärmebehandlung für mehr Flexibilität, Vorbiegen der Feile und Zugang aus verschiedenen Winkeln möglich
						</li>
						<li>
							Reduziertes Risiko des Feilenbruchs und mehr Widerstand gegen zyklische Ermüdung
						</li>
						<li>
							Rotation mit höherer Drehzahl für mehr Leistung bei niedrigerem Drehmoment
						</li>
						<li>
							Flexible Spülkanüle mit zwei seitlichen Austrittsöffnungen sorgen für eine Spülung bis dicht an den Apex
						</li>
						<li>
							Spritzgussgefertigte / Abgestimmte Guttaperchaspitzen mit mit präzisem Sitz und akkuratem Tug Back.
						</li>
					</ul>
				</div>
				<div>
					<img src={truNatomyGraphic} alt=""/>
				</div>
			</Grid>

			<Spacer />

			<Grid gridTemplateColumns="repeat(3, 1fr)" className="small-text">
				<div style={{position: "relative"}}>
					<p>
						<strong>
							Scouting
						</strong>
					</p>
					<p>
						TruNatomy™<br />
						Orifice Modifier<br />
						(20/08)
					</p>
					<img src={truNatomyKanaleingang} alt="ready steel" />
					<Arrow />
				</div>
				<div style={{position: "relative"}}>
					<p>
						<strong>Gleitpfad</strong>
					</p>
					<p>
						TruNatomy™<br />
						Glider<br />
						(17/02)
					</p>
					<img src={truNatomyGleitpfad} alt="Gold glider" />
					<Arrow />
				</div>
				<div>
					<p>
						<strong>Aufbereitung</strong>
					</p>
					<p>
						TruNatomy™<br />
						Shaping Feile Prime<br />
						(26/.04v)	
					</p>
					<img src={truNatomyAufbereitung} alt="Gold glider" />
				</div>
			</Grid>

			<Spacer />

			<Grid gridTemplateColumns="8fr 4fr" style={{alignItems: 'center'}}>
				<p>
					Der TruNatomy™ Orifice Modifier formt den Kanaleingang, um einen idealen und zugleich dentinschonenden Zugang zu schaffen. Es ist kein geradliniger Zugang notwendig.²
				</p>

				<Grid gridTemplateColumns="1fr 1fr" className="small-text" >
					<div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
						<img src={traditionelleEndodontischeKavität} alt="traditionelleEndodontischeKavität" style={{height: 102}}/>
						<p>
							Traditionelle 
							endodontische 
							Kavität
						</p>
					</div>
					<div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
						<img src={dentinschonendeEndodontischeKavität} alt="dentinschonendeEndodontischeKavität" style={{height: 102}}/>
						<p>
							Dentinschonende 
							endodontische 
							Kavität
						</p>
					</div>
				</Grid>
			</Grid>

			<Grid gridTemplateColumns="2.5fr 2fr 7.5fr" style={{alignItems: 'center'}}>
				<img src={trunamomyDentinLegende} alt="Trunamoy Dentin Legende"/>
				<img src={trunamomyDentin} alt="Trunamoy Dentin"/>

				<h2>
					TruNatomy™ schont so viel Dentin wie möglich und erlaubt dadurch mehr Optionen für die restaurative Versorgung sowie eine längere Lebensdauer der natürlichen Zähne.²
				</h2>
			</Grid>

			<Footnotes>
				¹ interne Daten
			</Footnotes>
		</div>
	)
}

export default SmartLiteProTruNatomy
