import { Link, LinkProps } from 'react-router-dom';
import styled from 'styled-components'
import MoreLink from '../morelink';

interface Props {
	calloutStyle?: string
}

type ColorsType<Ids extends string> = { [Id in Ids]: string };


const colors : ColorsType<string> = {
	secondary : 'var(--color__secondary)',
	default : 'var(--color__primary)',
	info : 'white',
}

const getColor = ( style: string ) : string => {
	const coloor : string = colors[style] ? colors[style] : colors.info;

	return coloor;
}

const Callout = styled.div<Props>`
	border: 2px solid;
	border-color: ${props => getColor( props.calloutStyle ?? 'default'  )};
	padding: 20px;

	> :first-child {
		margin-top: 0;
	}
`

export default Callout

interface CalloutLinkProps extends LinkProps{
	withMorelink?: boolean
}

export const CalloutLink : React.FC<CalloutLinkProps>= ({style, withMorelink, children, ...otherProps}) => {

	let linkStyle : React.CSSProperties= {color: "inherit", textDecoration: 'none', display: "grid"};
	if( style ) linkStyle = {...linkStyle, ...style};

	return (
		<Link style={linkStyle} {...otherProps}>
			<Callout>
				{children}

				{
					withMorelink && (
						<MoreLink to={otherProps.to}>
							Mehr erfahren
						</MoreLink>
					)
				}
			</Callout>
		</Link>
	)
}	