import Section from '../../components/section'

import restaurationenAlternativeGraphic from './img/restaurationen-alternative.png'

interface Props {
	
}
const RestaurationenAlternative = (props: Props) => {
	return (
		<div>
			<Section>
				<h1>Vorteilhafte Eigenschaften/Nutzen</h1>

				<ul className="small-text">
					<li>
						Härtet zuverlässig bis in die Tiefe aus
					</li>
					<li>
						Benötigt wenig Arbeitsschritte
					</li>
					<li>
						Kein Ansätzen oder Bonden
					</li>
					<li>
						Verkürzt die Behandlungszeit & man erreicht schnell einen Punkt an dem Kontamination keine Rolle spielt
					</li>
				</ul>


			</Section>

				<h1>Surefil one</h1>
				<p>
					Kompromisslos leistungsfähig und effizient:
				</p>
				<ul>
					<li>
						So langlebig wie ein Komposit
					</li>
					<li>
						So einfach wie ein Glasionomer
					</li>
					<li>
						Ohne Bonding
					</li>
					<li>
						Ohne Schichten – auch bei tiefen Kavitäten
					</li>
					<li>
						Dualhärtend: Sofortiges Finieren & Polieren nach Oberflächen-Lichthärtung möglich
					</li>
				</ul>

				<img src={restaurationenAlternativeGraphic} alt="Surefil one" style={{ width: '100%', maxWidth: 705 }}/>

		</div>
	)
}

export default RestaurationenAlternative
