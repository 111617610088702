import Grid from '../../components/grid'
import Section from '../../components/section'

import enhance1Graphic from './img/enhance-1.png'
import enhance2Graphic from './img/enhance-2.png'
import enhance3Graphic from './img/enhance-3.png'

interface Props {
	
}

const RestaurationenKompositHerausforderung6 = (props: Props) => {
	return (
		<div>
			<Section>
				<h1>Enhance®</h1>

				<Grid gridTemplateColumns="4fr 8fr">

					<div className="small-text">
						<p>
							<strong>Herausforderung</strong>
						</p>
						<p>
							Effizientes Finieren 
						</p>
						<p>
							<strong>Lösung</strong> Enhance®
						</p>

						<ul className="small-text">
							<li>
								Finieren in einem Schritt mit einem Instrument
							</li>
							<li>
								Abtrag wird durch die Höhe des Arbeitsdrucks kontrolliert
							</li>
							<li>
								Bietet eine glattere Oberfläche für dauerhafte Schönheit und Qualität der Füllung
							</li>
						</ul>
					</div>

					<div>

						<Grid gridTemplateColumns="repeat(3, 1fr)">
							<div>
								<img src={enhance1Graphic} alt="Schritt 1" className="with-border" width="180"/>
							</div>
							<div>
								<img src={enhance2Graphic} alt="Schritt 2" className="with-border" width="180"/>
							</div>
							<div>
								<img src={enhance3Graphic} alt="Schritt 3" className="with-border" width="180"/>
							</div>
						</Grid>
						<p className="small-text" style={{maxWidth: "none"}}>
							1. Überschuss-Entfernung und Konturierung <br />
							2. Finish <br />
							3. Klasse II Füllung mit Ceram.x Spectra™ ST nach dem Fingieren mit Enhance®
						</p>
					</div>
				</Grid>
			</Section>
		</div>
	)
}

export default RestaurationenKompositHerausforderung6
