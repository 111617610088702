import styled from 'styled-components'

const Spacer = styled.span`
	display: block;
	height: 100px;
`

export default Spacer

export const SmallSpacer = styled.span`
	display: block;
	height: 50px;
`
