import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
	@font-face {
		font-family: "Gotham";
		font-weight: 300;
		src: url("./fonts/gotham-light.otf") format("opentype");
		font-display: swap;
	}

	@font-face {
		font-family: "Gotham";
		font-weight: 300;
		font-style: italic;
		src: url("./fonts/gotham-light-italic.otf") format("opentype");
		font-display: swap;
	}

	@font-face {
		font-family: "Gotham";
		font-weight: 500;
		src: url("./fonts/gotham-medium.otf") format("opentype");
		font-display: swap;
	}

	@font-face {
		font-family: "Gotham";
		font-weight: 500;
		font-style: italic;
		src: url("./fonts/gotham-medium-italic.otf") format("opentype");
		font-display: swap;
	}

	:root {
		--color__primary: #F7A600;
		--color__secondary: #0080C7;
		--color__on_secondary: #fff;
		--color__on_primary: #000;
		--color__background: #3C3C3B;

		--font-weight__light: 300;
		--font-weight__medium: 500;
	}

	body {
		font-family: "Gotham", sans-serif;
		font-weight: var(--font-weight__light);
		background-color: var(--color__background);
		color: white;
		padding: 40px 42px;
		margin: 0;
		max-width: 100vw;
		overflow-x: hidden;
	}

	* {
		box-sizing: border-box;
	}

	h1,
	h2 {
		font-weight: var(--font-weight__light);
	}

	h3,
	strong {
		font-weight: var(--font-weight__medium);
	}

	h1 {
		font-size: 40px;
	}

	h2 {
		font-size: 20px;
		font-weight: var(--font-weight__medium);
	}

	h3 {
		margin-top: 40px;
	}

	p,
	ul {
		font-size: 20px;
		line-height: 1.2em;
		margin-bottom: 1.2em;
		margin-top: 1.2em;
		font-weight: var(--font-weight__light);
		max-width: 540px;
	}

	p:first-child {
		margin-top: 0;
	}


	ul {
		padding-left: 20px;
	}

	.small-text,
	.small-text p,
	.small-text ul {
		font-size: 16px
	}

	li {
		margin-bottom: 4px;
	}

	h1,
	ul {
		margin-bottom: 40px;
	}

	.list-headline {
		color: var(--color__primary);
		font-weight: var (--font-weight__medium);

		&::before {
			content: "— ";
		}
	}

	.with-border {
		border: 1px solid white;
	}

	img {
		max-width: 100%;
	}


	@media (max-width: 800px) {
		h1 {
			font-size: 28px;
		}

		p,
		ul {
			font-size: 18px;
		}
	}
`

export default GlobalStyle