import Grid from '../../components/grid'

import chirurgieGraphic from './img/chirurgie.png'

interface Props {
	
}

const Chirurgie = (props: Props) => {

	return (
		<div>
			<div>
				<h1>
					SiroLaser Blue - Chirurgie
				</h1>
				<Grid gridTemplateColumns="9fr 3fr">
					<div>
						<ul>
								<li>
									Hohe Präzision bei chirurgischen Eingriffen
								</li>
								<li>
									Geringe Schädigung des umliegenden Gewebes im Vergleich zur HF-Chirurgie
								</li>
								<li>
									Reduzierte Blutung und Bakteriämie
								</li>
								<li>
									Bakterienbarriere – Schutz vor Infektion
								</li>
								<li>
									Narbenfreie Heilung ohne Naht
								</li>
								<li>
									Kaum postoperative Wundschmerzen
								</li>
								<li>
									Unkomplizierte Wundheilung
								</li>
						</ul>
					</div>
					<div style={{textAlign: 'right', width: "100%"}}>
						<img src={chirurgieGraphic} alt="Paradontologie" style={{width: 185}}  />
					</div>
				</Grid>
			</div>
		</div>
	)
}

export default Chirurgie
