import { CalloutLink } from '../../components/callout'
import Footnotes from '../../components/footnotes'
import Grid from '../../components/grid'
import Section from '../../components/section'
import Spacer from '../../components/spacer'

import siroLaserBlueGraphic from './img/SiroLaserBlue.png'
import spuelungGraphic from './img/spuelung.png'

interface Props {
	
}

const SpuelungAktivierung = (props: Props) => {
	return (
		<div>
			<Section>
				<h1>Aktivierung der Spülflüssigkeit zur weiteren Keimreduktion</h1>
				<Grid gridTemplateColumns="7fr 5fr" style={{alignItems: "center"}}>
					<div>
						<p>
							<strong>
								Endoactivator®<br />
								Vollständige Reinigung für vollständige Füllung
							</strong>
						</p>

						<p>
							Aktivieren Sie beim Entfernen von Debris und Schmierschicht Ihre Spüllösungen mit dem Endoactivator®. Eine mit
							entsprechender Technik aktivierte unverdünnte Natriumhypochlorit-Lösung (NaOCl) tötet mehr Bakterien ab und
							löst mehr Gewebe auf als eine nicht aktivierte Spüllösung.¹
						</p>

						<ul>
							<li>
								Sicherere Anwendung durch flexible,  nicht-schneidende Kunststoff-Aufsätze (Activator Tips)
							</li>
							<li>
								Effektive hydrodynamische Strömung für ein erbessertes Entfernen von Debris, Schmierschicht und Biofilm
							</li>
							<li>
								Einfache Handhabung
							</li>
						</ul>
					</div>
					<img src={spuelungGraphic} alt="Spülung" />
					
				</Grid>
			</Section>

			<Spacer />

			<h1>SiroLaser Blue</h1>
			<Grid gridTemplateColumns="7fr 5fr">
				<div>
					<p>
						Endodontale Keimreduktion Laser-Dekontamination des Wurzelkanals und sogar darüber hinaus. Ziel ist es, Keime und Bakterien im Wurzelkanal und sogar bis zu 1000 µm in den Dentintubuli zu reduzieren.
					</p>
					<div style={{display: "flex", marginLeft: "auto"}}>
						<CalloutLink to="/sirolaser" style={{display: 'inline-block', marginLeft: "auto"}} withMorelink>
								<p>
									SiroLaser Blue - <br />
									vielfältige Einsatzgebiete
								</p>
						</CalloutLink>
					</div>
				</div>
				<div style={{paddingLeft: "50px"}}>
					<img src={siroLaserBlueGraphic} alt="Siro Laser Blue"/>
					<p style={{transform: 'translateY(-100px)'}}>Siro Laser Blue</p>
				</div>
			</Grid>

			<Spacer />

			<Footnotes>
				¹ Effectiveness of different final irrigant activation protocols on smear layer removal in curved canals.; Caron G, Machtou P.; J Endod. 2010.
			</Footnotes>
		</div>
	)
}

export default SpuelungAktivierung
