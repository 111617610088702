import Grid from '../../components/grid'

import endodontieGraphic from './img/endodontie.png'

interface Props {
	
}

const Endodontie = (props: Props) => {

	return (
		<div>
			<div>
				<h1>
					SiroLaser Blue - Endodontie
				</h1>
				<Grid gridTemplateColumns="9fr 3fr">
					<div>
						<ul>
								<li>
									Keimreduktion in den Wurzelkanälen und in den Dentintubuli
								</li>
						</ul>
						<p>
							Die konventionelle Spülung reicht bis zu ca. 100 µm tief in die Tubuli, mit dem Laser erreicht man eine Eindringtiefe bis zu 1000 µm in den Dentintubuli (Keimreduzierende Wirkung bis 500 µm).
						</p>
						<ul  style={{marginTop: 50}}>
							<li>
								Bessere Langzeitprognose für endodontisch behandelte Zähne
							</li>
							<li>
								Zusätzliche Liquidation zur Kassen-Endo
							</li>
						</ul>
					</div>
					<div style={{textAlign: 'right', width: "100%"}}>
						<img src={endodontieGraphic} alt="Endodontie" style={{width: 185}}  />
					</div>
				</Grid>
			</div>
		</div>
	)
}

export default Endodontie
