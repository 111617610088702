import Grid from '../../components/grid'
import Section from '../../components/section'

import ausgangssituationGraphic from './img/ceramx-ausgangssituation.png'
import okkusalerLaesionGraphic from './img/ceramx-okkusaler-laesion.png'
import indirectResorationCeramx from './img/ceramx-indirect-restoration-ceramx.png'
import cloudFarbeGraphic from './img/ceramx-cloud-farbe.png'
import cloudFarbeA2Graphic from './img/ceramx-cloud-farbe-a2.png'
import indirectRestorationAfterCementationAndPolishingGraphic from './img/ceramx-indirect-restoration-after-cementation-and-polishing.png'

interface Props {
	
}

const RestaurationenKompositHerausforderung5 = (props: Props) => {
	return (
		<div>
			<Section>
				<h1>Ceram.x Spectra™ ST</h1>

				<Grid gridTemplateColumns="1fr 1fr">

					<div className="small-text">
						<p>
							<strong>
								Herausforderung
							</strong>
						</p>
						<p>
							Effiziente Okklusalanatomie
						</p>
						<p>
							<strong>Lösung</strong> Ceram.x Spectra™ ST
						</p>

						<ul className="small-text">
							<li>
								Einfache Adaptation am Zahn – kein Kleben am Instrument 
							</li>
							<li>
								Leichte Modellierbarkeit und hohe Standfestigkeit
							</li>
							<li>
								Schnelle und einfache Hochglanzpolitur
							</li>
						</ul>
					</div>

					<div>
						<h2>
							Frontzahnfüllung
						</h2>
						<p className="small-text">
							Ian Cline, Großbritannien
						</p>

						<Grid gridTemplateColumns="1fr 1fr">
							<div>
								<img src={ausgangssituationGraphic} alt="Ausgangssituation" className="with-border" style={{maxWidth: 227}}/>
								<p className="small-text">
									Ausgangssituation
								</p>
							</div>
							<div>
								<img src={cloudFarbeGraphic} alt="Ausgangssituation" className="with-border" style={{maxWidth: 227}}/>
								<p className="small-text">
									Versorgung mit Cloud-Farbe A3 von 32 bis 43
								</p>
							</div>

							<div>
								<img src={okkusalerLaesionGraphic} alt="Ausgangssituation" className="with-border" style={{maxWidth: 227}}/>
								<p className="small-text">
									Zahn 36 mit okkusaler Läsion
								</p>
							</div>
							<div>
								<img src={cloudFarbeA2Graphic} alt="Ausgangssituation" className="with-border" style={{maxWidth: 227}}/>
								<p className="small-text">
									Zahn 36 nach Versorgung Mit Cloud-Farbe A2
								</p>
							</div>

							<div>
								<img src={indirectResorationCeramx} alt="Ausgangssituation" className="with-border" style={{maxWidth: 227}}/>
								<p className="small-text">
									Indirekte Restaurantion aus Cloud-Farbe A2
								</p>
							</div>

							<div>
								<img src={indirectRestorationAfterCementationAndPolishingGraphic} alt="Ausgangssituation" className="with-border" style={{maxWidth: 227}}/>
								<p className="small-text">
									Indirekte Restaurantion bei 26 und 27 nach Zementierung und Politur
								</p>
							</div>
						</Grid>
					</div>
				</Grid>
			</Section>
		</div>
	)
}

export default RestaurationenKompositHerausforderung5
