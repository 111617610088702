import styled from 'styled-components'

interface Props {
	options: string[]
}

const List = styled.ol`
	list-style: none;
	margin-left: 0;
	padding-left: 0;
	font-size: 16px;

	li {
		padding-left: 27px;
	}
`

const ListMarker = styled.span`
	display: inline-block;
	width: 17px;
	height: 17px;
	font-size: 14px;
	background-color: white;
	border-radius: 50%;
	margin-right: 10px;
	margin-left: -27px;
	color: #008AC3;
	border: 1px solid #008AC3;
	text-align: center;
	font-weight: var(--font-weight__medium);
	font-size: 14px;
`

const StyledOl : React.FC<Props> = ({options}) => {
	return (
		<List>
			{
				options.map((option, index) => {
					return (
						<li>
							<ListMarker>
								{
									index + 1
								}
							</ListMarker>
							{ option }
						</li>
					)
				})
			}
		</List>
	)
}

export default StyledOl
