import Grid from '../../components/grid'
import Section from '../../components/section'
import Spacer from '../../components/spacer'

import sirolaserGraphic from './img/sirolaser.png';
import vorherGraphic from './img/sirolaser-vorher.jpg';
import danachGraphic from './img/sirolaser-direkt-danach.jpg';
import { CalloutLink } from '../../components/callout'

interface Props {
	
}

const SiroLaser = (props: Props) => {
	return (
		<div>
			<Section>
				<Grid gridTemplateColumns="7fr 5fr">
					<div>
						<h1>SiroLaser Blue</h1>
						<p>
							Kein Retraktionsfaden mehr notwendig  Klar definierte, „saubere“ und „trockene“ Präparationsränder Vermeidung von Verletzungen und Blutung des Zahnfleisches  Optimale Technik für die Abdrucknahme (Verkürzte Behandlungsdauer)
						</p>
					</div>
					<img src={sirolaserGraphic} alt="Sirolaser" style={{width: "100%"}} />

				</Grid>
				<Spacer />

				<Grid gridTemplateColumns="5fr 7fr" style={{alignItems: "flex-end", marginTop: -150}}>
					<div>
						<Grid gridTemplateColumns="3fr 2fr" style={{alignItems: "flex-end", marginBottom: 20}}>
								<img src={vorherGraphic} alt="vorher" style={{width: "100%"}} />
								<p>
									Vorher
								</p>
						</Grid>
						<Grid gridTemplateColumns="3fr 2fr" style={{alignItems: "flex-end"}}>
								<img src={danachGraphic} alt="direkt danach" style={{width: "100%"}} />
								<p>
									Direkt danach
								</p>
						</Grid>

					</div>

					<div>
						<CalloutLink to="/sirolaser" style={{maxWidth: 295}} withMorelink>
							<p>
								SiroLaser Blue -
								vielfältige Einsatzgebiete
							</p>
						</CalloutLink>
					</div>
				</Grid>
{/* 
				<p className="small-text">
					Strahlenprofil der SmartLite® Pro im Vergleich zu 3 anderen Lampen zeigen die Lichtverteilung an der Spitze des Lichtleiters.1
				</p> */}
				{/* <img src={strahlenprofilGraphic} alt="Strahlenprofil" style={{width: "100%"}} /> */}
				{/* <p className="small-text">
					Alle Bilder wurden im Abstand von 0 mm unter identischen Bedingungen aufgenommen und zeigen das ungefilterte Strahlenprofil aller Wellenlängen von blauem und violettem Licht (350-550 nm).
				</p> */}
			</Section>
		</div>
	)
}

export default SiroLaser
