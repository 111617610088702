import Footnotes from '../../components/footnotes'
import Grid from '../../components/grid'
import Section from '../../components/section'
import Spacer from '../../components/spacer'

import ahTempKanueleGraphic from './img/ah-temp-kanuele.png'
import calxylKanueleGraphic from './img/calxyl-kanuele.png'

interface Props {
	
}

const TemporaereEinlage = (props: Props) => {
	return (
		<div>
			<Section>
				<h1>Temporäre Einlage</h1>

				<p>
					Ohne die Platzierung einer desinfizierenden Einlage zwischen zwei Behandlungen, können sich die verbliebenen Bakterien bis auf das ursprüngliche Niveau vervielfachen.¹
				</p>
				<p>
				Auch wenn eine vollständige Keimfreiheit im Kanal nicht immer erzielt werden kann, bleibt Calciumhydroxid das beste verfügbare Medikament, um die verbleibende mikrobielle Flora weiter zu reduzieren.²
				</p>
			</Section>

			<h1>
				AH Temp™
			</h1>
			<Grid gridTemplateColumns="7fr 5fr">
				<div>
					<ul>
						<li>
							Präzises Arbeiten bis in tiefe Kanalregionen durch ultradünne, lange und gebogene Kanüle mit Silikonstopp zur Bestimmung der exakten Länge
						</li>
						<li>
							Stark verlangsamtes Austrocknen
						</li>
						<li>
							Sicher durch effektive Desinfektion (hoher pH-Wert)
						</li>
						<li>
							Exzellente Röntgenopazität
						</li>
					</ul>
				</div>
				<div>
					<Grid gridTemplateColumns="1fr 1fr">
						<div>
							<img src={ahTempKanueleGraphic} alt="AH Temp™ Kanüle"/>
							<p className="small-text">
								AH Temp™ Kanüle
							</p>
						</div>
						<div>
							<img src={calxylKanueleGraphic} alt="Calxyl¹ Kanüle"/>
							<p className="small-text">
								Calxyl¹ Kanüle
							</p>
						</div>
					</Grid>
				</div>
			</Grid>

			<Spacer />

			<Footnotes>
				<p>
					¹ Law A, Messer H: An evidence-based analysis of the antibacterial effectiveness of intracanal medicaments. J Endod. 2004 Oct;30(10):689-94<br />
				</p>
				<p>
					² Keine eingetragene Marke von Dentsply Sirona
				</p>
			</Footnotes>
		</div>
	)
}

export default TemporaereEinlage
