import React from 'react'
import { Link } from 'react-router-dom'
import Button, { ButtonGroup } from '../../components/button'
import Footnotes from '../../components/footnotes'
import Section from '../../components/section'
import { SmallSpacer } from '../../components/spacer'

interface Props {
	
}

const FrageSmartLitePro = (props: Props) => {
	return (
		<div>
			<Section>
				<h1>Sind Sie sicher, dass Ihr Füllungsmaterial bis in die Tiefe aushärten können?</h1>
				<p>
					Bei vielen Lampen erreichen weniger als 35% der angegebenen Leistung den Kavitätenboden eines approximalen Kastens.¹
				</p>
				<SmallSpacer />
				<ButtonGroup>
					<Link to="/">
						<Button>
							Ja, ich bin mir sicher
						</Button>
					</Link>
					<Link to="/lichthaertung/smartlitepro">
						<Button buttonStyle="secondary">
							Nein, ich bin nicht sicher
						</Button>
					</Link>
				</ButtonGroup>
			</Section>
			<Footnotes>
				<p>
					¹ Irradiance Value Comparison among commercially available curing lights. BlueLight Analytics (2012). Daten auf Anfrage erhältlich, siehe Kontaktdaten.
				</p>
			</Footnotes>
		</div>
	)
}

export default FrageSmartLitePro
